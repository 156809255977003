$blog-cta-text-width: 466px;

// LAYOUT
// --------------------------------------------------

html {
  max-width: 100%;
}

body {
  max-width: 100%;

  @include mobile {
    padding: 0 $spacing-unit / 2;
    overflow-x: hidden;
  }
}

.page-content {
  padding: $spacing-unit * 2 0 $spacing-unit 0;
  flex: 1 0 auto;

  @include mobile {
    padding-top: $spacing-unit;
  }
}

.permalink {
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  margin-left: -1em;
}

h2:hover > .permalink {
  opacity: 1;
  color: $color-blue;
}
h3 > .permalink,
h3 > .permalink:hover {
  color: $text-color;
}
h3:hover > .permalink {
  opacity: 1;
}
h3.relatedHeader {
  font-size: 32px;
}
.permalink:hover {
  color: $text-color;
  background-color: transparent;
}





label, .formItem-label {
  font-family: $body-font-family;
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  color: $color-grey;
}

.buttonLabel {
  font-family: $header-font-family;
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  color: white;
  letter-spacing: 0.05em;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.row--flexStart {
  justify-content: flex-start;
}
.row--nowrap {
  flex-wrap: nowrap;
}


.block {
  display: block;
  text-align: center;
}
.block--video {
  margin: 0;
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;
}




a.imageLink {
  background-color: transparent;
}


// HEADER LINK (Icon to left of Header)
// --------------------------------------------------

.headerLink {
  font-family: $chrome-font-family;
  font-size: $chrome-font-size;
  line-height: $chrome-line-height;
  font-weight: $chrome-font-weight;
  font-variant-ligatures: none;
  color: $color-grey;
  margin-left: 25px;
  display: block;
  text-align: center;
}

.headerLink--mobile {
  font-size: 16px;
  line-height: normal;
  margin: 0;
  min-width: 33%;
  margin-top: $spacing-unit;
}

.headerLink-text {
  display: block;
  margin: auto;
}

.headerLink:first-child {
  margin-left: 0;
}

.headerLink,
.headerLink svg,
.headerLink path {
  transition: color 0.2s, opacity 0.2s;
}

.headerLink:hover,
.headerLink--mobile {
  color: $color-red;
}

.headerLink,
.headerLink:hover {
  background-color: transparent;
}

.headerLink svg {
  display: block;
  opacity: 0;
  width: 22px;
  height: 22px;
  margin: auto;
  margin-bottom: 6px;
}

.headerLink--mobile svg {
  width: $spacing-unit;
  height: $spacing-unit;
  animation: none;
}

.headerLink .fillStd {
  fill: $color-red;
}

.headerLink--enterprise svg {
    fill: $color-red;
}

.headerLink:hover svg,
.headerLink:hover path,
.headerLink--mobile svg,
.headerLink--mobile path {
  opacity: 1;
}

.ctaLink {
  display: inline-block;
  margin: 0;
  font-weight: 400;
}

.svgButton {
  display: inline-block;
  padding: 0;
  position: relative;
}

// BRANDED TILT ELEMENTS (clipped corners)
// --------------------------------------------------
.brandButton {
  display: inline-block;
  padding: 0 $spacing-unit;
  position: relative;
}
a.brandButton {
  background-color: transparent;
}
.brandButton-bg {
  display: flex;
  opacity: $opacity-light;
  height: $brand-button-height;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transition: opacity 300ms;
}
.u-heightBrandButton {
  height: $brand-button-height !important;
}
.brandButton:hover .brandButton-bg {
  opacity: 1;
}
button.svgButton, button.brandButton {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.brandButton-bg > svg {
  fill: $brand-color;
}
.brandButton--red .brandButton-bg > svg {
  fill: $color-red;
}
.brandButton-bgFill {
  background-color: $brand-color;
  flex: 1;
}
.brandButton--red .brandButton-bgFill {
  background-color: $color-red;
}
.brandButton-text {
  color: white;
  display: block;
  line-height: 40px;
  text-align: center;
}

.brandBox {
  padding: $spacing-unit;
  position: relative;
}
.brandBox-bg {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.brandBox-left, .brandBox-right {
  width: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.brandBox-left > .brandBox-bgFill {
  border-radius: 0 0 0 5px;
}
.brandBox-right > .brandBox-bgFill {
  border-radius: 0 5px 0 0px;
}
.brandBox-bgFill {
  background-color: $color-grey-lightest;
  flex: 1;
}
.brandBox-bg svg {
  fill: $color-grey-lightest;
  width: 40px;
  height: 80px;
}
.brandBox ul {
  margin-left: $spacing-unit;
}

.brandBox--cta {
  padding: 0;
}
.brandBox--cta .ctaSubscribe-text {
  padding: $spacing-unit * 0.75 $spacing-unit;
}
.brandBox--cta .brandBox-right {
  flex-grow: 0.5;
  visibility: hidden;

  @include docs-mobile {
    visibility: visible;
    flex-grow: 0;
  }
}
.u-hideHeader .ctaSubscribe-header {
  display: none;
}



#scrollToTop {
  display: block;
  float: left;

  @include docs-mobile {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
}

.editOnGitHub {
  float: right;
  display: block;
}
.editOnGitHub svg {
  padding-top: 2px;

  .fillStd {
    fill: $color-red;
    transition: fill 0.2s;
  }
}
.editOnGitHub:hover svg .fillStd {
  fill: white;
}
.editOnGitHub-text {
  float: right;
  padding-left: $spacing-unit / 4;
}

.svgButton--menu svg {
  min-width: 40px;
  height: 40px;
}
.svgButton--menu .fillStd {
  fill: $brand-color;
  opacity: $opacity-light;
}

.svgButton--x svg {
  min-width: 0;
  height: 40px;
}

.svgButton--x {
  padding: 0 4px;
  width: 40px;
}

/** Use the standard color fill for the given icon */
.fillStd {
  fill: $color-grey;
}

.bigIcon .fillStd,
h3 .fillStd,
h4 .fillStd {
  fill: $color-blue;
}

.h3-icon {
  margin-right: $spacing-unit/2;
  margin-bottom: $spacing-unit/4;
  float: left;
}

.h4-icon {
  margin-right: $spacing-unit/2;
  margin-bottom: $spacing-unit/4;
  float: left;
}




.formItem {
  display: block;
  position: relative;

  @include mobile {
    padding-bottom: $spacing-unit;
  }
}
.sidebar .formItem {
  padding-bottom: $spacing-unit / 2;
}
.formItem label,
.formItem .formItem-label {
  display: block;
  padding-bottom: 9px;
}
.formItem-option label {
  padding-bottom: 0;
}
.formItem input,
.formItem textarea {
  font-family: $body-font-family;
  font-weight: 300;
  font-size: 18px;
  padding: 0 $spacing-unit/4;
  background-color: $color-grey-lightest;
  border: none;
  line-height: 1.5 * $spacing-unit;
  width: 100%;
  max-width: $col-3of4-width;
}
.formItem-option {
  box-sizing: border-box;
  position: relative;
}
.formItem-option label {
  padding-top: 0.375 * $spacing-unit;
  cursor: pointer;
  color: $color-grey-dark;
  padding-left: 2 * $spacing-unit;
}
input[type=checkbox] {
  visibility: hidden;
  cursor: pointer;
  padding: 0;
  width: 0;
  height: 0;
}
.formItem-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin-left: 0.25 * $spacing-unit;
  margin-top: 0.125 * $spacing-unit;
  width: $spacing-unit * 1.25;
  height: $spacing-unit * 1.25;
  background-color: $color-grey-lightest;
  border-radius: 3px;
  border: 1px solid $color-grey-dark;
  transition: background-color 0.2s ease-in-out;
  pointer-events: none;
}
input[type=checkbox]:checked + .formItem-checkbox {
  background-color: $color-green;
}
.language-bash code,
.language-powershell code {
  user-select: all;
}
.twitter-tweet {
  margin: $spacing-unit !important;
}


// "IS THIS HELPFUL" FORM
// --------------------------------------------------
.helpfulForm {
  margin: 0 auto 1.5rem auto;
}

.helpfulForm-content {
  min-height: 2.75em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.helpfulForm-button {
  font-family: $header-font-family;
  font-size: 20px;
  font-weight: 400;
  color: $color-grey-dark;
  background-color: transparent;
  display: inline-block;
  transition: background-color 300ms ease;
  padding: $spacing-unit / 8 $spacing-unit / 2;
  margin: 0 $spacing-unit / 4;
  border: 1px solid $color-grey;
  font-family: inherit;
  cursor: pointer;
}
.helpfulForm-button--yes:hover {
  background-color: rgba($color-blue, 0.3);
}
.helpfulForm-button--no:hover {
  background-color: rgba($color-red, 0.3);
}



// PAGINATION
// --------------------------------------------------

.pagination {
  display: flex;
  margin: $spacing-unit auto $spacing-unit auto;
  align-items: center;
  justify-content: space-between;
}
.pagination--top {
  margin: - $spacing-unit auto $spacing-unit auto;
  
  @include mobile {
    margin-top: 0;
  }
}
.pagination-next,
.pagination-previous {
  display: flex;
  align-items: center;
}
.pagination-link,
.pagination-icon {
  display: inline-block;
}
.pagination-icon {
  font-size: 32px;
  color: $color-blue;
}

