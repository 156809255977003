.wrapper--enterprise {
  width: 100%;
  max-width: $tilt2-page-width;
}

.Enterprise-sectionHeading {
  @include font-heading;
}

// Hero --------------------------------------------------
.Enterprise-heroTitle {
  @include font-hero;    
}
.Enterprise-hero {
  margin-bottom: $spacing-unit;

  @include mobileAndAbove {
    display: flex;
    margin-bottom: $spacing-unit * 2;
  }
}

// Most of the text
.Enterprise-hero-text {
  @include mobileAndBelow {
    margin-bottom: $spacing-unit * 2;
  }
  @include mobileAndAbove {
    flex: 1;
  }
}
.Enterprise-hero-text-subhead {
  @include font-hero-subhead;
  margin-bottom: $spacing-unit;
}
.Enterprise-hero-text-detail {
  @include mobileAndAbove {
    display: flex;
  }

  p {
    @include font-body;
    
    @include mobileAndAbove {
      margin-right: $spacing-unit * 2;
    }
  }

}
.Enterprise-hero-cta {
  @include mobileAndAbove {
    margin-left: $spacing-unit * 2;
  }
}
.Enterprise-hero-cta-title {
  @include font-heading-small;
}

.calendly-inline-widget {
  height: 600px;
  @include shadow-small;
  border-radius: $tilt2-border-radius-large;
  margin-top: 28px;

  @include mobileAndAbove {
    width: 320px;
  }

  > iframe {
    border-radius: $tilt2-border-radius-large;
  }
}

// Feature List --------------------------------------------------
.Enterprise-featureList {
  @include reset-list-style;
  margin-bottom: $spacing-unit * 2;

  @include mobileAndAbove {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacing-unit;
  }
}
.Enterprise-featureItem-title {
  @include font-heading-small;
}
.Enterprise-featureItem-text {
  @include font-body;
}
