.bio {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.02em;
}

.bio-image {
  width: 224px;
  height: 176px;
  clip-path: polygon(13.17% 0.88%, 1.50% 9.96%, 0.00% 13.38%, 0.00% 97.19%, 2.21% 100.00%, 84.38% 100.00%, 86.83% 99.12%, 98.50% 90.04%, 100.00% 86.62%, 100.00% 2.81%, 97.79% 0.00%, 15.62% 0.00%, 13.17% 0.88%);
  object-fit: cover;
  margin-bottom: $spacing-unit/2;

  @include media-query($col-2of3-width) {
    width: auto;
    height: auto;
  }
}
