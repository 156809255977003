// These are styles for the initial design from 2018, by Sascha Mombartz
// Since the Landing page and Docs were more recently revamped,
// most of these variables apply to the blog.


// Typography
// --------------------------------------------------
$header-font-family: attribute-text, sans-serif;
$mono-font-family: attribute-mono, monospace;

$mono-font-size: 16px;
$mono-font-weight: 400;
$mono-line-height: $body-line-height;

$small-font-size:  13px;
$small-line-height: 18px;

$chrome-font-size: 12px;
$chrome-font-family: $header-font-family;
$chrome-line-height: 18px;
$chrome-font-weight: 400;

// Layout
// --------------------------------------------------
$brand-button-height: 1.25 * $spacing-unit;


// Color
// --------------------------------------------------
$color-grey: #9a9a9a;
$color-grey-light: #cacaca;
$color-grey-lightest: #f1f1f1;
$color-grey-dark: #606060;
$color-grey-darkest: #434343;

$text-color:       $color-grey-dark;
$background-color: #ffffff;

// Opacity
$alpha-transparent: 0.15;

$table-text-align: left;


// Utility
// --------------------------------------------------
$col-1of3-width: $spacing-unit * 7;
$col-2of3-width: $spacing-unit * 15;
$col-1of4-width: $spacing-unit * 5;
$col-3of4-width: $spacing-unit * 17;
$col-1of2-width: $spacing-unit * 11;



@mixin relative-font-size($ratio) {
  font-size: $body-font-size * $ratio;
}



// Animation
$anim-duration-short: 0.15s;
$anim-duration: 0.3s;