/*
 Adapted from the Rouge github theme
*/
.highlight .cm {
  color: $color-grey;
}
.highlight .cp {
  color: $color-grey;
  font-weight: bold;
}
.highlight .c1 {
  color: $color-grey;
}
.highlight .cs {
  color: $color-grey;
  font-weight: bold;
}
.highlight .c, .highlight .cd {
  color: #999988;
}
.highlight .err {
  color: #a61717;
}
.highlight .gd {
  color: $color-grey-darkest;
}
.highlight .ge {
  color: $color-grey-darkest;
}
.highlight .gr {
  color: #aa0000;
}
.highlight .gh {
  color: $color-grey;
}
.highlight .gi {
  color: $color-grey-darkest;
}
.highlight .go {
  color: #888888;
}
.highlight .gp {
  color: #555555;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gu {
  color: #aaaaaa;
}
.highlight .gt {
  color: #aa0000;
}
.highlight .kc {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .kd {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .kn {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .kp {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .kr {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .kt {
  color: #445588;
  font-weight: bold;
}
.highlight .k, .highlight .kv {
  color: $color-grey-dark;
  font-weight: bold;
}
.highlight .mf {
  color: $color-blue;
}
.highlight .mh {
  color: $color-blue;
}
.highlight .il {
  color: $color-blue;
}
.highlight .mi {
  color: $color-blue;
}
.highlight .mo {
  color: $color-blue;
}
.highlight .m, .highlight .mb, .highlight .mx {
  color: $color-blue;
}
.highlight .sb {
  color: #d14;
}
.highlight .sc {
  color: #d14;
}
.highlight .sd {
  color: #d14;
}
.highlight .s2 {
  color: #d14;
}
.highlight .se {
  color: #d14;
}
.highlight .sh {
  color: #d14;
}
.highlight .si {
  color: #d14;
}
.highlight .sx {
  color: #d14;
}
.highlight .sr {
  color: #009926;
}
.highlight .s1 {
  color: #d14;
}
.highlight .ss {
  color: #990073;
}
.highlight .s {
  color: $color-red;
}
.highlight .na {
  color: $color-blue;
}
.highlight .bp {
  color: $color-grey;
}
.highlight .nb {
  color: $color-grey-dark;
}
.highlight .nc {
  color: #445588;
  font-weight: bold;
}
.highlight .no {
  color: #008080;
}
.highlight .nd {
  color: #3c5d5d;
  font-weight: bold;
}
.highlight .ni {
  color: #800080;
}
.highlight .ne {
  color: $color-red;
  font-weight: bold;
}
.highlight .nf {
  color: $color-red;
  font-weight: bold;
}
.highlight .nl {
  color: #990000;
  font-weight: bold;
}
.highlight .nn {
  color: #555555;
}
.highlight .nt {
  color: #000080;
}
.highlight .vc {
  color: #008080;
}
.highlight .vg {
  color: #008080;
}
.highlight .vi {
  color: #008080;
}
.highlight .nv {
  color: #008080;
}
.highlight .ow {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .o {
  color: $color-grey-darkest;
  font-weight: bold;
}
.highlight .w {
  color: #bbbbbb;
}
