@charset "utf-8";

// Legacy Clearfix
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}


// Layout
// --------------------------------------------------

// Make the scrollbar always visible so that the header doesn't shift on pagenav
html {
  overflow-y:scroll;
}

main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

.wrapper {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  @extend %clearfix;
}



// Typography
// --------------------------------------------------
body {
  font-family: $tilt2-font-sans;
  font-size: $tilt2-font-size-body;
  line-height: $tilt2-line-height-body;
  color: $text-color;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  // Typography tweaks
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}



// HTML Elements
// --------------------------------------------------

img {
  max-width: 100%;
  vertical-align: middle;
}

figure > img {
  display: block;
}

// These are generally overridden with custom spacing…
h1, h2, h3, h4, h5, h6,
// …while these are used semantically, not for its default layout…
figure, ul, ol, li, blockquote {
  margin: 0;
  padding: 0;
}


a {
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s;
}


svg {
  overflow: visible;
}

input, textarea {
  box-sizing: border-box;
}

hr {
  margin-top: $spacing-unit * 1.5;
  margin-bottom: $spacing-unit * 1.5;
  margin-left: 0;
  margin-right: 0;
  height: 3px;
  background-color: $tilt2-color-gray-lightest;
  border: 0 none;
}


// HTML Elements - Code
// --------------------------------------------------
code {
  font-family: $tilt2-font-mono;
  font-weight: inherit;  
}

h1, h2, h3, h4, h5, h6 {
  code {
    font-size: 85%; // Match size of headline font
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: $tilt2-border-radius-large;
    border: 1px solid $tilt2-color-gray-lighter;
    background-color: $tilt2-color-gray-lightest;
  }
}

pre {
   font-family: $tilt2-font-mono;
   font-weight: $tilt2-font-weight-mono-light;
   line-height: $tilt2-line-height-mono;
   overflow-x: auto;
   background-color: $tilt2-color-gray-lightest;
   padding: $spacing-unit/2;
   border-radius: $tilt2-border-radius-large;
}
