@charset "utf-8";

/* Animation effects */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headerLink svg,
.headerLink path,
.headerLink g {
  animation-fill-mode: forwards;
}

.headerLink--logo-imagemark svg {
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
}
.headerLink--logo-imagemark:hover svg {
  animation-name: spin;
}
.siteNav-popout .headerLink--logo-imagemark:hover svg {
  animation-name: none;
}

@keyframes dots-fade-in-3of3 {
 0% {fill: $color-red;}
 66.67% {fill: $color-red;}
 85.71% {fill: white; }
 100% {fill: white;}
}
@keyframes dots-fade-in-2of3 {
  0% {fill: $color-red;}
  38.10% {fill: $color-red;}
  57.14% {fill: white;}
  100% {fill: white;}
}
@keyframes dots-fade-in-1of3{
  0% {fill: $color-red;}
  4.76% {fill: $color-red;}
 28.57% {fill: white;}
 100% {fill: white;}
}

.headerLink--contact-anim path {
  animation-duration: 0.7s;
}
.headerLink--contact-anim:hover .dot1 {
  animation-name: dots-fade-in-1of3;
}
.headerLink--contact-anim:hover .dot2 {
  animation-name: dots-fade-in-2of3;
}
.headerLink--contact-anim:hover .dot3 {
  animation-name: dots-fade-in-3of3;
}

@keyframes docs-fade-in-1of4 {
  0% { fill: $color-red; }
  3.33% { fill: $color-red; }
  40% { fill: white; }
  100% { fill: white; }
}
@keyframes docs-fade-in-2of4 {
  0% { fill: $color-red; }
  23.33% { fill: $color-red; }
  56.67% { fill: white; }
  100% { fill: white; }
}
@keyframes docs-fade-in-3of4 {
  0% { fill: $color-red; }
  40% { fill: $color-red; }
  73.33% { fill: white; }
  100% { fill: white; }
}
@keyframes docs-fade-in-4of4 {
  0% { fill: $color-red; }
  56.67% { fill: $color-red; }
  83.33% { fill: white; }
  100% { fill: white; }
}

.headerLink--docs-anim path {
  animation-duration: 1s;
}
.headerLink--docs-anim:hover .part1 {
  animation-name: docs-fade-in-1of4;
}
.headerLink--docs-anim:hover .part2 {
  animation-name: docs-fade-in-2of4;
}
.headerLink--docs-anim:hover .part3 {
  animation-name: docs-fade-in-3of4;
}
.headerLink--docs-anim:hover .part4 {
  animation-name: docs-fade-in-4of4;
}

@keyframes blog-pen {
  0% { transform: translate(-1px,0px); }
  12.5% { transform: translate(1.199188px,-1px); }
  37.5% { transform: translate(4.698376px,1.552899px); }
  50% { transform: translate(7.448782px,-0.22355px); }
  62.5% { transform: translate(8.199188px,0px); }
  100% { transform: translate(-0.800812px,0px); }
}
@keyframes blog-line {
  0% { d: path('M8,17L2,17C1.44772,17,1,17.4477,1,18C1,18.5523,1.44772,19,2,19L8,19C8.55229,19,9,18.5523,9,18C9,17.4477,8.55228,17,8,17Z'); }
  62.5% { d: path('M18,17L2,17C1.44772,17,1,17.4477,1,18C1,18.5523,1.44772,19,2,19L18,19C18.5523,19,19,18.5523,19,18C19,17.4477,18.5523,17,18,17Z'); }
  100% { d: path('M8,17L2,17C1.44772,17,1,17.4477,1,18C1,18.5523,1.44772,19,2,19L8,19C8.5523,19,9,18.5523,9,18C9,17.4477,8.5523,17,8,17Z'); }
}
.headerLink--blog:hover .blog-pen {
  animation: 0.8s ease both blog-pen;
}
.headerLink--blog:hover .blog-line {
  animation: 0.8s ease both blog-line;
}
.siteNav-popout .headerLink--blog:hover .blog-pen,
.siteNav-popout .headerLink--blog:hover .blog-line {
  animation-name: none;
}

.imageLink--logo-wordmark-anim .notch {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: transform;
}
.imageLink--logo-wordmark-anim .notch--1 {
  transition-delay: 0.1s;
  transform: translate(568.0799865722656px, 30.780078895390034px) rotate(0deg) translate(-568.0799865722656px, -30.780078895390034px);
}
.imageLink--logo-wordmark-anim:hover .notch--1 {
  transform: translate(568.0799865722656px, 30.780078895390034px) rotate(90deg) translate(-568.0799865722656px, -30.780078895390034px);
}
.imageLink--logo-wordmark-anim .notch--2 {
  transition-delay: 0.06s;
  transform: translate(31.439985513687134px, 30.77007481828332px) rotate(0deg) translate(-31.439985513687134px, -30.77007481828332px);
}
.imageLink--logo-wordmark-anim:hover .notch--2 {
  transform: translate(31.439985513687134px, 30.77007481828332px) rotate(-90deg) translate(-31.439985513687134px, -30.77007481828332px);
}
.imageLink--logo-wordmark-anim .notch--3 {
  transition-delay: 0.13s;
  transform: translate(238.06499481201172px, 30.77007481828332px) rotate(0deg) translate(-238.06499481201172px, -30.77007481828332px);
}
.imageLink--logo-wordmark-anim:hover .notch--3 {
  transform: translate(238.06499481201172px, 30.77007481828332px) rotate(90deg) translate(-238.06499481201172px, -30.77007481828332px);;
}
.imageLink--logo-wordmark-anim .notch--4 {
  transition-delay: 0.03s;
  transform: translate(405.0400085449219px, 169.33999633789062px) rotate(0deg) translate(-405.0400085449219px, -169.33999633789062px);
}
.imageLink--logo-wordmark-anim:hover .notch--4 {
  transform: translate(405.0400085449219px, 169.33999633789062px) rotate(-90deg) translate(-405.0400085449219px, -169.33999633789062px);
}
