
.newsHeader {
  display: flex;
  justify-content: center;  
  border-bottom: 2px dotted $tilt2-color-gray;
  // Hack to make the banner full width:
  margin-left: $spacing-unit * -1;
  margin-right: $spacing-unit * -1;

  @include mobileAndBelow {
    margin-top: 100px; // Push so it's visible below .siteHeader-logoAndMenuButton
    border-top: 2px dotted $tilt2-color-gray;
  }
  
  .body--docs & {
    justify-content: start;
    margin: 0;
  }
}

.newsHeader-inner {
  display: flex;
  align-items: center;  
  font-size: 18px;
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;
  @include reset-link-style;  

  
  @include mobileAndAbove {
    font-size: 24px;
    padding-top: $spacing-unit * 1.5;
    padding-bottom: $spacing-unit * 1.5;
  }

  .body--docs & {
    font-size: 18px;
    padding-top: $spacing-unit * 0.25;
    padding-bottom: $spacing-unit * 0.25;
    padding-left: $spacing-unit;
  }

  strong {
    transition: 300ms color ease;
  }
}

.newsHeader-inner:hover {
  strong {
    color: $color-green;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.newsHeader-icon {
  height: 36px;
  margin-left: $spacing-unit * 0.25;

  @include mobileAndAbove {
    height: 45px;
  }
  
  .body--docs & {
    height: 36px;
  }
}

.siteHeader2 {
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-left: auto;

  @include mobileAndAbove {
    max-width: $tilt2-header-width;
    padding-top: $spacing-unit * 2;
    padding-bottom: $spacing-unit;
  }
}

.siteHeader-logoLink {
  opacity: 1;
  transition: opacity $anim-duration-short ease;

  &:hover {
    background-color: transparent;
  }
  &:active {
    opacity: 0.8;
  }
}
.siteHeader-logoLink-svg {
    transition: transform $anim-duration ease-in-out;

  .siteHeader-logoLink:hover & {
    transform: scale(1.05);
  }
}

.siteHeader-nav {
  @include mobileAndAbove {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.siteHeader-navList {
  list-style-type: none;
  margin-bottom: 0; 

  @include mobileAndAbove {
    display: flex;
    justify-content: flex-end;

    li + li {
      margin-left: $spacing-unit / 4;
    }
  }
}

.siteHeader-navLink {
  font-family: $tilt2-font-sans;
  font-size: $tilt2-button-font-size; 
  height: $tilt2-button-height;
  line-height: $tilt2-button-height;
  padding-left: $spacing-unit / 4;
  padding-right: $spacing-unit / 4;
  border-radius: $tilt2-border-radius;
  color: $tilt2-color-gray;
  display: flex;
  justify-content: center;
  border: 1px solid $tilt2-color-gray;
  
  &:hover {
    color: $color-white;
    background-color: $tilt2-color-gray;
  }
}

.siteHeader-navLink--primary {
  color: $color-green;
  border-color: $color-green;

  &:hover {
    color: $color-white;
    background-color: $color-green;
  }
}

.siteHeader-navLink--github {
  color: $tilt2-color-gray;
  border-color: $tilt2-color-gray-lighter;
  margin-left: $spacing-unit / 2;
  margin-right: $spacing-unit / 2;

  &:hover {
    color: $color-white;
    background-color: $tilt2-color-gray;
  }
}


.siteHeader-navLink-icon {
  border-right: 1px solid $tilt2-color-gray-lighter;
  margin-right:  $spacing-unit / 4;
}

.siteHeader-navLink {
  padding-left: $spacing-unit / 4;
  padding-right: $spacing-unit / 4;
  
  .fillStd {
    fill: $color-yellow;
  }
}

// --------------------------------------------------
// MOBILE LAYOUT

.siteHeader2 {
  @include mobileAndBelow {
    height: 0; // This helps adjust space between newsHeader and content below
  }
}

.siteHeader-navList {
  @include mobileAndBelow {
    li + li {
      margin-top: $spacing-unit * 0.75;
    }
  }
}

.siteHeader-logoAndMenuButton {
  @include mobileAndBelow {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: $color-white;
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit / 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: $z-siteHeader--mobile;
  }
}
.siteHeader-logoLink-svg {
  @include mobileAndBelow {
    width: 85px; // Eyeballed
  }
}

.siteHeader-nav {
  @include mobileAndBelow {
    transform: translateY(-1000px); // Should be taller than any reasonable screen height
    transition: transform $anim-duration ease;
    opacity: 0;

    .siteHeader2.has-openMenu & {
      background-color: $color-white;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: $z-siteHeader-nav--mobile;
      transform: translateY(0);
      opacity: 1;
    }
  }
}


.siteHeader-menuButton {
  @include reset-button-style;
  cursor: pointer;
  
  @include mobileAndAbove {
    display: none;
  }

  .fillStd {
    fill: $tilt2-color-gray;
    transition: fill $anim-duration ease;
  }

  &:hover {
    .fillStd {
      fill: $color-blue;
    }
  }
}

.siteHeader-menuButton-close {
  display: none;
  .siteHeader2.has-openMenu & {
    display: inline;  
  }
}

.siteHeader-menuButton-menu {
  display: inline;
  .siteHeader2.has-openMenu & {
    display: none;
  }
}
