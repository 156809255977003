// Color
// --------------------------------------------------

.grey {
  color: $color-grey;
}

.u-fillRed {
  fill: $color-red !important;
}

.u-fillBlue {
  fill: $color-blue !important;
}

.u-fillWhite {
  fill: white !important;
}

.u-fillGreen {
  fill: $color-green !important;
}

.u-fillGreenLightest {
  fill: $color-green-lightest !important;
}
.u-colorRed {
  color: $color-red !important;
}
.u-muted {
  opacity: $opacity-light !important;
}



// Layout
// --------------------------------------------------

.u-clearBoth {
  clear: both !important;
}


.u-blockTwoThirds {
  display: block;
  max-width: $col-3of4-width;
}

.flexblock {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}


.col-3of4 {
  display: block;
  width: 100%;
  max-width: $col-3of4-width;
}

.col-1of2,
.wrapper--blog .col-1of3 {
  display: block;
  width: 100%;
  max-width: $col-1of2-width;
}



.col-1of4 {
  display: block;
  width: 100%;
  max-width: $col-1of4-width;
}

.col-1of3 {
  display: block;
  width: 100%;
  max-width: $col-1of3-width;

  @include media-query($content-width) {
    max-width: 45%;
  }
  @include media-query($col-2of3-width) {
    max-width: 100%;
  }
}


.u-padding16 {
  padding: 16px !important;
}


.u-margin1 {
  margin: $spacing-unit !important;
}

.u-margin1_5 {
  margin: $spacing-unit * 1.5 !important;
}
.u-marginLeft0_25 {
  margin-left: $spacing-unit * 0.25 !important;
}
.u-marginLeft2 {
  margin-left: $spacing-unit * 2 !important;
}
.u-marginRight0_5 {
  margin-right: $spacing-unit * 0.5 !important;
}

.u-marginRight0_75 {
  margin-right: $spacing-unit * 0.75 !important;
}

.u-marginRightUnit {
  margin-right: $spacing-unit !important;
}
.u-marginRight2 {
  margin-right: $spacing-unit * 2 !important;
}

.u-marginBottom0 {
  margin-bottom: 0 !important;
}

.u-marginBottom0_25 {
  margin-bottom: $spacing-unit * 0.25 !important;
}

.u-marginBottom0_5 {
  margin-bottom: $spacing-unit * 0.5 !important;
}
.u-marginBottom0_75 {
  margin-bottom: $spacing-unit * 0.75 !important;
}
.u-marginBottomUnit,
.u-marginBottom1 {
  margin-bottom: $spacing-unit !important;
}

.u-marginBottom1_25 {
  margin-bottom: $spacing-unit * 1.25 !important;
}

.u-marginBottom1_5 {
  margin-bottom: $spacing-unit * 1.5 !important;
}

.u-marginBottom1_75 {
  margin-bottom: $spacing-unit * 1.75 !important;
}

.u-marginBottom2 {
  margin-bottom: $spacing-unit * 2 !important;
}

.u-marginBottom2_5 {
  margin-bottom: $spacing-unit * 2.5 !important;
}

.u-marginBottom3 {
  margin-bottom: $spacing-unit * 3 !important;
}

.u-marginTop0_5 {
  margin-top: $spacing-unit * 0.5 !important;
}

.u-marginTopUnit,
.u-marginTop1 {
  margin-top: $spacing-unit !important;
}

.u-marginTop1_25 {
  margin-top: $spacing-unit * 1.25 !important;
}

.u-marginTop1_5 {
  margin-top: $spacing-unit * 1.5 !important;
}

.u-marginTop1_75 {
  margin-top: $spacing-unit * 1.75 !important;
}

.u-marginTop2 {
  margin-top: $spacing-unit * 2 !important;
}

.u-marginTopNeg1 {
  margin-top: $spacing-unit * -1 !important;
}

.u-marginBottomUnitOnMobile {
  @include mobile {
    margin-bottom: $spacing-unit !important;
  }
}
.u-marginBottom0OnMobile {
  @include mobile {
    margin-bottom: 0 !important;
  }
}
.u-marginBottom0_75OnMobile {
  @include mobile {
    margin-bottom: $spacing-unit * 0.75 !important;
  }
}
.u-marginBottom1_75OnMobile {
  @include mobile {
    margin-bottom: $spacing-unit * 1.75 !important;
  }
}
.u-marginAuto {
  margin: auto !important;
}
.u-maxWidthContent {
  max-width: $content-width !important;
}
.u-maxWidth100OnMobile {
  @include mobile {
    max-width: 100%;
  }
}

.u-textAlignCenter {
  text-align: center !important;
}
.u-textAlignRight {
  text-align: right !important;
}



// RESPONSIVE
// --------------------------------------------------

.u-hideOnMobile {
  @include mobile {
    display: none !important;
  }
}

.u-hideOnDocsMobile {
  @include docs-mobile {
    display: none !important;
  }
}

.u-showOnlyOnMobile {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.u-showOnlyOnDocsMobile {
  display: none !important;

  @include docs-mobile {
    display: block !important;
  }
}

.u-minHeight72OnMobile {
  @include mobile {
    min-height: 72px;
  }
}


// EFFECTS
// --------------------------------------------------

.u-boxShadow {
  box-shadow: 8px 10px 16px 16px $color-grey-lightest !important;
}

.copy-to-clipboard {
  display: block;
  margin: -($spacing-unit / 2) 0 ($spacing-unit / 8) auto;
  margin-right: $spacing-unit * 0.5;

  font-family: $chrome-font-family;
  color: $color-red;
  fill: $color-red;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    color: $color-white;
    fill: $color-white;
    background-color: $color-red;
  }

  svg {
    height: 16px;
    vertical-align: middle;
  }
}

